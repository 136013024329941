import useIsResMobile from "lib/hooks/useIsResMobile";

interface WrapperProps {
  className?: string;
}

/**
 * Wrapper used to contain content within a fixed width and padding
 *
 * XS ~ SM: width: 100%; padding: 0 20px;
 * MD ~ : width: 1000px; padding: 0 20px;
 */
const Wrapper: React.FC<WrapperProps> = ({ children, className }) => {
  const isResMobile = useIsResMobile();

  return (
    <div
      css={`
        width: 100%;
        max-width: ${!isResMobile ? "1000px" : "100%"};
        margin: 0 auto;
        padding: 0 20px;
      `}
      className={className}
    >
      {children}
    </div>
  );
};

export default Wrapper;
