import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import IconGoogle from "lib/assets/google.svg";
import IconFacebook from "lib/assets/facebook.svg";
import IconEmail from "lib/assets/email.svg";
import { SpinFixed } from "lib/components/Spin";
import { useAuth } from "domain/auth/authContext";
import { useEvent } from "lib/contexts/eventContext";
import { CustomEventLabel, ObjectEvent } from "lib/enums";

export const ContinueWithGoogle: React.FC = () => {
  return <SocialLoginButton type="google" />;
};

export const ContinueWithFacebook: React.FC = () => {
  return <SocialLoginButton type="facebook" />;
};

export const ContinueWithEmail: React.FC = () => {
  const { tag } = useEvent();
  const { push, query } = useRouter();
  const handleOnClick = useCallback(() => {
    tag?.(CustomEventLabel.SignUpLogInMethodType, { value: "Email" });
    push({ pathname: "/sign-up-email", query });
  }, [tag, push, query]);
  return (
    <LoginButton
      label="Email"
      icon={<IconEmail />}
      onClick={handleOnClick} // preserve state
    />
  );
};

interface SocialLoginButtonProps {
  type: "google" | "facebook";
}

const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({ type }) => {
  const { tag, track } = useEvent();
  const [pending, setPending] = useState(false);
  const { doLoginWithRedirect, doHandleRedirectResult } = useAuth();

  const handleOnClick = useCallback(async () => {
    tag?.(CustomEventLabel.SignUpLogInMethodType, {
      value: type === "google" ? "Google" : "Facebook",
    });
    setPending(true);
    sessionStorage.setItem("isRedirectMode", "yes");
    await doLoginWithRedirect(type);
  }, [setPending, type, tag, doLoginWithRedirect]);

  useEffect(() => {
    const isRedirectMode = sessionStorage.getItem("isRedirectMode");
    sessionStorage.removeItem("isRedirectMode");
    const loginFromRedirect = async () => {
      setPending(true);
      const userSocial = await doHandleRedirectResult();
      if (userSocial?.isNewUser) {
        track(ObjectEvent.UserSignUpCompleted, { method: userSocial.provider });
      }
      setPending(false);
    };
    if (isRedirectMode) {
      loginFromRedirect();
    }
  }, [doHandleRedirectResult, track]);

  return (
    <>
      <LoginButton
        label={type === "google" ? "Google" : "facebook"}
        icon={type === "google" ? <IconGoogle /> : <IconFacebook />}
        onClick={handleOnClick}
      />
      {pending && <SpinFixed />}
    </>
  );
};

interface LoginButtonProps {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const LoginButton: React.FC<LoginButtonProps> = ({ label, icon, onClick }) => (
  <ButtonContainer type="button" onClick={onClick}>
    {label}
    <IconWrapper>{icon}</IconWrapper>
  </ButtonContainer>
);

const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 28px;
  width: 100%;
  height: 56px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.1s ease-out;
  &:hover,
  &:focus {
    border-color: #195dee;
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 18px;
`;
