import { useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Link from "next/link";
import { useForm, Controller } from "react-hook-form";
import MuiBox from "@material-ui/core/Box";
import MuiTypography from "@material-ui/core/Typography";
import Button from "lib/components/Button";
import { ContinueWithGoogle } from "domain/auth/SocialLoginButton";
import Stack from "lib/components/Stack";
import { useAuth, useRedirectIfLoggedIn } from "domain/auth/authContext";
import { isValidEmail } from "domain/auth/isValidEmail";
import PageLayout from "lib/components/PageLayout";
import { TextFieldStandard } from "lib/components/TextField";
import { useLayout } from "lib/contexts/layoutContext";
import { useEvent } from "lib/contexts/eventContext";
import { CustomEventLabel } from "lib/enums";
import Wrapper from "lib/components/Wrapper";
import useIsResMobile from "lib/hooks/useIsResMobile";

const Login = () => {
  const { query } = useRouter();
  const { tag } = useEvent();
  const { doLogin } = useAuth();
  const { doOpenAlert } = useLayout();
  const { control, errors, formState, handleSubmit } = useForm<FormDataLogin>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { isSubmitting, isSubmitted, isValid } = formState;
  const handleOnSubmit = handleSubmit(async (data: FormDataLogin) => {
    tag?.(CustomEventLabel.LogInSelectLogInMethodLogInCTA);
    try {
      await doLogin(data);
    } catch (err) {
      doOpenAlert({
        content: `There was a problem. Please ${
          err.name === "LoginFailed"
            ? "check your credentials again"
            : "retry shortly"
        }.`,
      });
    }
  });

  const handleOnClickForgotPassword = useCallback(() => {
    tag?.(CustomEventLabel.LogInSelectLogInMethodForgotPassword);
  }, [tag]);

  useEffect(() => {
    tag?.(CustomEventLabel.ViewUserLogin);
  }, [tag]);

  const isResMobile = useIsResMobile();

  const canAccess = useRedirectIfLoggedIn();

  if (!canAccess) {
    return null;
  }

  return (
    <PageLayout>
      <Wrapper>
        <MuiBox my={isResMobile ? 8 : 12} mx="auto" maxWidth={320}>
          <Head>
            <title>Login | Lawfully</title>
          </Head>

          <MuiBox mb={8}>
            <MuiTypography variant="h2" component="h1">
              Login
            </MuiTypography>
          </MuiBox>

          <form noValidate onSubmit={handleOnSubmit}>
            <MuiBox mb={6}>
              <Stack spacing={4}>
                <Controller
                  as={TextFieldStandard}
                  control={control}
                  name="email"
                  type="email"
                  placeholder="Email"
                  fullWidth
                  autoFocus
                  rules={{
                    required: true,
                    validate: (val) => isValidEmail(val) || "Invalid email",
                  }}
                  InputProps={{
                    inputProps: {
                      noValidate: "novalidate",
                    },
                  }}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
                <Controller
                  as={TextFieldStandard}
                  control={control}
                  name="password"
                  type="password"
                  placeholder="Password"
                  fullWidth
                  rules={{ required: true }}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
                <Link href={{ pathname: "/forgot-password", query }}>
                  <a onClick={handleOnClickForgotPassword}>Forgot password?</a>
                </Link>
              </Stack>
            </MuiBox>

            <MuiBox mb={12}>
              <Button
                type="submit"
                disabled={isSubmitting || (isSubmitted && !isValid)}
                round
              >
                LOG IN
              </Button>
            </MuiBox>

            <Stack spacing="14px">
              <ContinueWithGoogle />
              <MuiTypography variant="body2">
                Don&apos;t have an account?{" "}
                <Link href={{ pathname: "/sign-up", query }}>Sign up</Link>
              </MuiTypography>
            </Stack>
          </form>
        </MuiBox>
      </Wrapper>
    </PageLayout>
  );
};

export default Login;
